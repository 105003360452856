import { navigate } from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'
import { FacebookIcon } from '../components/FacebookIcon'
import { GigCard } from '../components/GigCard'
import { HollowButton } from '../components/HollowButton'
import { InstagramIcon } from '../components/InstagramIcon'
import Layout from '../components/Layout'
import { ModalProvider, ModalContents, ModalOpenButton } from '../components/Modal'
import { Title } from '../components/Title'
import ModalGigDetails from '../components/ModalGigDetails'
import ModalMenu from '../components/ModalMenu'
import FacebookEvents from '../assets/data/events.json'

const gigItem = {
  place: {
    name: 'Some Awesome Venue'
  }
}

const Container = styled.div`
  color: white;
  text-align: center;
`

function clickMe() {
  navigate('/home')
}

const ComponentExplorerPage = () => {
  return (
    <Layout>
      <Container>
        <div class="grid">
          <div class="col-12">
            <ModalProvider>
              <ModalOpenButton>
                <HollowButton>open modal menu</HollowButton>
              </ModalOpenButton>
              <ModalContents >
                <ModalMenu />
              </ModalContents>
            </ModalProvider>
            <br />
            <br />
            <ModalProvider>
              <ModalOpenButton>
                <HollowButton>open modal gigs</HollowButton>
              </ModalOpenButton>
              <ModalContents>
                <ModalGigDetails item={gigItem} />
              </ModalContents>
            </ModalProvider>
            <p>&lt;Modal/&gt;</p>
          </div>
          <div class="col-12">
            <h1>Component Explorer</h1>
          </div>
          <div class="col-12">
            <Title text="Upcoming gigs" />
            <p>&lt;Title/&gt;</p>
          </div>
          <div class="col-12">
            <HollowButton onClick={clickMe}>go home</HollowButton>
            <p>&lt;HollowButton/&gt;</p>
          </div>
          <div class="col-12">
            <FacebookIcon size="52" />
            <FacebookIcon size="64" />
            <InstagramIcon size="52" />
            <p>&lt;FacebookIcon/&gt; + &lt;InstagramIcon/&gt;</p>
          </div>
          <div class="col-12">
            <GigCard item={FacebookEvents[0]} width={375} />
            <p>&lt;GigCard/&gt;</p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ComponentExplorerPage
